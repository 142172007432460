<template>
  <div class="pasenger_form">
    <b-form ref="form" class="d-flex justify-content-between" @submit="checkForm">
      <h4> {{ $t('product-page.' + user.generation) + (device == 'desktop' ? ' : ' : '') }} </h4>
      <b-form-group :label="device == 'desktop' ? $t('booking.first-name') : ''" label-for="input-2">
        <b-form-input v-model="firstName" :placeholder="$t('booking.first-name')" required :state='fNameState' :readonly="readOnly" :class="!fNameState ? 'danger' : ''" @change="UpdateName('first')" @keypress="isLetter($event)" aria-describedby="input-firstname-feedback"></b-form-input>
        <!-- <b-form-invalid-feedback id="input-firstname-feedback">
          {{$t("booking.no-empty")}}
        </b-form-invalid-feedback> -->
      </b-form-group>
      <b-form-group :label="device == 'desktop' ? $t('booking.last-name') : ''" label-for="input-2">
        <b-form-input v-model="lastName" :placeholder="$t('booking.last-name')" required :state='lNameState' :readonly="readOnly" :class="!lNameState ? 'danger' : ''" @change="UpdateName('last')" @keypress="isLetter($event)" aria-describedby='input-lastname-feedback'></b-form-input>
        <!-- <b-form-invalid-feedback id="input-lastname-feedback">
          {{$t("booking.no-empty")}}
        </b-form-invalid-feedback> -->
      </b-form-group>
      <b-form-group :label="device == 'desktop' ? $t('booking.gender') : ''" label-for="input-3">
        <b-form-select :aria-invalid="true" v-model="paxType" :options="paxTypes" required :state="paxState" :disabled="readOnly" @change="updateUserInfo" :class="!paxState ? 'danger' : ''" aria-describedby='select-pax-feedback'></b-form-select>
        <!-- <b-form-invalid-feedback id="input-pax-feedback">
          {{$t("booking.no-empty")}}
        </b-form-invalid-feedback> -->
      </b-form-group>
      <b-form-group>
        <label v-if="device == 'desktop'">{{ $t('booking.date-birth') }}</label>
        <date-picker required :state='birthState' ref="datepicker" v-model="birthDate" :config="limitDate" :placeholder="$t('booking.date-birth')" @dp-change="updateUserInfo" :class="[(!birthState ? 'danger' : ''), 'text-right']" />
        <div class="icon-form" v-if="birthState">
          <i class="fas fa-check"></i>
        </div>
        <div class='dxb-dob-tooltip'>
          <div class="btn-dxb-dob-tooltip" @click="showDobTooltip = !showDobTooltip"><i class="fa fa-circle-exclamation"></i></div>
          <div class="dxb-dob-tooltip-msg" v-show="showDobTooltip">
              <h6>{{ $t("booking.dxb-dob-msg") }}</h6>
          </div>
        </div>
      </b-form-group>
      <b-form-group :label="device == 'desktop' ? $t('booking.phone') : ''" label-for="input-2">
        <b-form-input v-model="phone" :placeholder="$t('booking.phone')" required :state="phoneState" :readonly="readOnly" @change="updateUserInfo" :class="!phoneState ? 'danger' : ''" aria-describedby="input-phone-feedback"></b-form-input>
        <!-- <b-form-invalid-feedback id="input-phone-feedback">
          {{$t("booking.no-empty")}}
        </b-form-invalid-feedback> -->
      </b-form-group>
      <b-form-group :label="device == 'desktop' ? $t('booking.email') : ''" label-for="input-1">
        <b-form-input v-model="email" type="email" placeholder="email@example.com" required :state="emailState" :readonly="readOnly" @change="updateUserInfo" :class="!emailState ? 'danger' : ''" aria-describedby='input-email-feedback'></b-form-input>
        <!-- <b-form-invalid-feedback id="input-email-feedback">
          {{$t("booking.no-empty")}}
        </b-form-invalid-feedback> -->
      </b-form-group>
      <b-button type="submit" variant="primary" class="d-none">{{ $t('booking.submit')
      }}</b-button>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  // BFormInvalidFeedback,
} from 'bootstrap-vue';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';

dayjs.extend(customParseFormat);

const maxMan = 120, maxChild = 12, maxInfant = 2;
const dayFormatShow = 'DD-MM-YYYY', dayFormatISO = 'YYYY-MM-DD';

export default {
  props: {
    user: {
      type: Object,
      default: null,
    },
    originDate: {
      type: String,
      default: dayjs(),
    },
    index: {
      type: Number,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    hotelId: {
      type: String,
      default: '',
    },
  },
  data() {
    const now = new Date(),
      minDate = new Date();
    minDate.setFullYear(now.getFullYear() - 2);
    return {
      paxTypes: [
        { value: 'M', text: this.$t('booking.mr') },
        { value: 'F', text: this.$t('booking.miss') },
        { value: 'C', text: this.$t('booking.children') },
        { value: 'I', text: this.$t('booking.infant') },
      ],
      firstName: '',
      lastName: '',
      paxType: 'M',
      email: '',
      phone: '',
      config: {
        format: 'DD-MM-YYYY',
        useCurrent: false,
        viewMode: 'years',
        maxDate: now,
      },
      configInfant: {
        format: 'DD-MM-YYYY',
        useCurrent: false,
        viewMode: 'years',
        maxDate: now,
        minDate,
      },
      birthDate: '01-01-1900',
      showDobTooltip: false,
    };
  },
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    // BFormInvalidFeedback,
    datePicker,
  },
  watch: {
    readOnly() {
      if (this.readOnly) {
        this.$refs.datepicker.dp.disable();
      } else {
        this.$refs.datepicker.dp.enable();
      }
    },
    lang: 'updateLabelWithLang',
    user: {
      handler() {
        this.setUserInformation();
        this.updateUserInfo();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      devMode: 'GET_MODE',
      stagingMode: 'GET_STAGING_MODE',
      isFcAgentMarketerMode: 'GET_FC_AGENT_MARKETER_MODE',
    }),
    maxAgeChild() {
      return this.hotelId && this.hotelId === '55116' ? 16 : maxChild;
    },
    limitDate() {
      let minDate = dayjs(this.originDate, dayFormatShow),
        maxDate = dayjs(this.originDate, dayFormatShow);
      let initDate = '1980-01-01';

      switch (this.user.generation) {
        case 'adult':
          maxDate = maxDate.subtract(maxMan, 'year');
          minDate = minDate.subtract(this.maxAgeChild, 'year');
          break;
        case 'child':
          maxDate = maxDate.subtract(this.maxAgeChild, 'year');
          minDate = minDate.subtract(maxInfant, 'year');
          initDate = maxDate.add(1, 'day').format(dayFormatISO);
          break;
        case 'infant':
          maxDate = maxDate.subtract(maxInfant, 'year');
          initDate = null;
          break;
        default:
      }
      return {
        format: dayFormatShow,
        useCurrent: false,
        viewMode: 'years',
        minDate: Date.parse(maxDate.format(dayFormatISO).toString()),
        maxDate: Date.parse(minDate.format(dayFormatISO).toString()),
        defaultDate: initDate,
      };
    },
    fNameState() {
      return !!this.firstName && this.firstName !== '';
    },
    lNameState() {
      return !!this.lastName && this.lastName !== '';
    },
    paxState() {
      return !!this.paxType && this.paxType !== '';
    },
    emailState() {
      if (this.isFcAgentMarketerMode && this.user.generation === 'adult') return true;
      return !!this.email && this.email !== '';
    },
    phoneState() {
      if (this.isFcAgentMarketerMode && this.user.generation === 'adult') return true;
      return !!this.phone && this.phone !== '';
    },
    birthState() {
      if (this.isFcAgentMarketerMode && this.user.generation === 'adult') return true;

      const depDate = dayjs(this.originDate, dayFormatShow);
      let birth = dayjs(this.birthDate, dayFormatShow);
      const retryState = JSON.parse(window.sessionStorage.getItem('retryState'));
      if (retryState && retryState.readOnly) {
        birth = dayjs(this.birthDate, dayFormatISO);
      }
      let boolAvailBirth = !!this.birthDate;
      if (boolAvailBirth) {
        switch (this.paxType) {
          case 'M':
            boolAvailBirth = birth.isBefore(depDate.subtract(this.maxAgeChild, 'year')) && birth.isAfter(depDate.subtract(maxMan, 'year'));
            break;
          case 'C':
            boolAvailBirth = birth.isBefore(depDate.subtract(maxInfant, 'year')) && birth.isAfter(depDate.subtract(this.maxAgeChild, 'year'));
            break;
          case 'I':
            boolAvailBirth = birth.isBefore(depDate) && birth.isAfter(depDate.subtract(maxInfant, 'year'));
            break;
          default:
        }
      }
      return boolAvailBirth;
    },
  },
  methods: {
    updateLabelWithLang() {
      this.paxTypes = [
        { value: 'M', text: this.$t('booking.mr') },
        { value: 'F', text: this.$t('booking.miss') },
        { value: 'C', text: this.$t('booking.children') },
        { value: 'I', text: this.$t('booking.infant') },
      ];
    },
    UpdateName(pKind) {
      if (pKind === 'first') {
        this.firstName = /^[a-zA-Z_ ]+$/.test(this.firstName) ? this.firstName : '';
        this.firstName = this.firstName.toUpperCase();
      } else if (pKind === 'last') {
        this.lastName = /^[a-zA-Z_ ]+$/.test(this.lastName) ? this.lastName : '';
        this.lastName = this.lastName.toUpperCase();
      }
      this.updateUserInfo();
    },
    updateUserInfo() {
      const userInfo = {
        validate: false,
        groupID: this.user.groupID,
        id: this.user.id,
        firstName: this.firstName,
        lastName: this.lastName,
        paxType: this.paxType,
        email: this.email,
        phone: this.phone,
        birthDate: this.birthDate,
      };
      if (
        this.fNameState
        && this.lNameState
        && this.paxState
        && this.birthState
        && ((this.user.generation === 'adult'
          && this.emailState
          && this.phoneState)
          || this.user.generation !== 'adult')
      ) {
        userInfo.validate = true;
      }
      this.$emit('updateUser', userInfo);
    },
    checkForm(ev) {
      ev.preventDefault();
    },
    limitPaxtypes() {
      switch (this.user.generation) {
        case 'adult':
          this.paxTypes[2].disabled = true;
          this.paxTypes[3].disabled = true;
          this.paxType = 'M';
          break;
        case 'child':
          this.paxTypes.forEach((el) => {
            el.disabled = true;
          });
          this.paxType = 'C';
          break;
        case 'infant':
          this.paxTypes.forEach((el) => {
            el.disabled = true;
          });
          this.paxType = 'I';
          break;
        default:
      }
    },
    setUserInformation() {
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
      this.email = this.user.email;
      this.phone = this.user.phone;
      this.birthDate = this.user.birthDate;
    },
    isLetter(e) {
      const char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z_ ]+$/.test(char)) return true;
      else e.preventDefault();
      return false;
    },
  },
  created() {
    this.limitPaxtypes();
    this.setUserInformation();
    if (this.devMode || this.stagingMode) {
      if (this.firstName === '') this.firstName = 'test';
      if (this.lastName === '') this.lastName = 'test';
      if (this.email === '') this.email = 'ofirfishler@gmail.com';
      if (this.phone === '') this.phone = '543320148';
      if (this.birthDate === '') this.birthDate = '15-08-2000';
      this.updateUserInfo();
    }
  },
  mounted() {
    if (this.readOnly) {
      this.$refs.datepicker.dp.disable();
    } else {
      this.$refs.datepicker.dp.enable();
    }
  },
};
</script>

<style>
.form-group label {
  font-size: 15px;
  display: inline-block;
  margin-bottom: 0.5rem;
}

.form-group input {
  font-size: 15px !important;
}

@media (max-width: 479px) {
  .pasenger_form fieldset.form-group {
    display: flex;
    margin: 0px;
    width: 50%;
    float: left;
  }
}
</style>

<style scoped>
.pasenger_form {
  box-sizing: border-box;
  margin-bottom: 15px;
  z-index: 91;
}

.passenger_body .pasenger_form h4 {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
  letter-spacing: 1px;
  width: 100%;
  max-width: 75px;
  line-height: 92px;
  position: relative;
}

.pasenger_form .form-group {
  position: relative;
  height: 76px;
  min-width: 100px;
  margin-right: 10px;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.pasenger_form .form-group .form-icon {
  content: '';
  position: absolute;
  bottom: 6px;
  left: 15px;
  font-size: 20px;
  color: #606060;
}

.pasenger_form fieldset {
  direction: ltr;
  margin-top: -3px;
}

.form-group .icon-form {
  content: '';
  position: absolute;
  bottom: 12px;
  left: 14px;
  color: #28a745;
}

.invalid-feedback {
  background-color: white;
  border-radius: 2px;
}

.form-control.is-valid,
.form-control.is-invalid {
  background-position: left calc(0.375em + 0.1875rem) center;
}

.custom-select.is-valid {
  border-color: #28a745;
  padding-right: 2rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat,
              #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center left 0.5rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.danger {
  border-color: #FF5733;
  outline: 0;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(233 102 102 / 60%);
}

.dxb-dob-tooltip {
  margin: 5px 0;
  display: flex;
}

.btn-dxb-dob-tooltip {
  margin-left: 5px;
  font-size: 22px;
  color: #FF5733;
  cursor: pointer;
}

.dxb-dob-tooltip-msg {
  margin-right: 5px;
  background-color: #FF5733;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  height: 28px;
}

@media (max-width: 479px) {
  .pasenger_form {
    padding: 5px 5px;
    border: 1px solid #e5e5e5;
  }

  .passenger_body .pasenger_form h4 {
    color: rgb(33, 37, 41);
    line-height: 1.2;
  }

  .pasenger_form .form-group {
    color: rgb(33, 37, 41) !important;
  }

  .pasenger_form .form-group label {
    margin-top: 12px;
  }

  .bootstrap-datetimepicker-widget.dropdown-menu {
    width: 50% !important;
  }
}

@media (max-width: 768px) {
  .pasenger_form form {
    display: inline-block !important;
  }

  .pasenger_form .form-group {
    width: 50%;
    display: inline-block;
    margin: auto;
    padding: 3px 10px;
    height: fit-content;
  }
}

@media (min-width: 750px) and (max-width: 1200px) {
  .passenger_box_two .pasenger_form form {
    display: inline-block !important;
  }

  .passenger_box_two .pasenger_form .form-group {
    width: 33%;
    display: inline-block !important;
    margin: auto;
    padding: 10px;
  }
}
</style>
